<template>
  <AwlCard v-bind="{...$attrs, ...$props}" class="awl-project-card" box-shadow>
    <template #title>
      <router-link :to="{ name: 'projects.show', params: { id: project.id } }" class="project-header">
        <div class="d-flex align-items-center mb-3">
            <img draggable="false" :src="project.image.thumbUrl" class="mr-3 shadow rounded-circle card-image">
            <h4 :title="project.name" class="project-name">{{ project.name }}</h4>
        </div>
      </router-link>
    </template>

    <template #default>
      <slot name="footer">
        <div class="card-footer d-flex align-items-center justify-content-end">
          <router-link :to="{ name: 'projects.edit', params: { id: project.id } }" class="btn btn-sm btn-outline-primary">Modifica</router-link>
        </div>
      </slot>
    </template>
  </AwlCard>
</template>

<script>

import rolesMixin from '../../../mixins/roles';

export default {
  mixins: [rolesMixin],
  components: {
    AwlCard: () => import('./AwlCard'),
  },
  props: {
    border: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      required: true,
    },
  },
};

</script>

<style lang="scss">

.awl-project-card {
  .card-image {
    height: 70px;
    object-fit: cover;
    width: 70px;
  }
  .project-header {
    color: $dark;
    &:hover {
      text-decoration: none !important;
    }
  }
  .project-name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .awl-card--header {
    padding: 1rem !important;
    padding-bottom: 0 !important;
    .user-role {
      font-size: 16px;
    }
  }

  .awl-card--title {
      color: $dark !important;
      text-transform: initial !important;
      font-size: 1.5rem;
      line-height: 1.25rem;
      margin-bottom: 0;
    }

  .awl-card--content {
    padding: 0 1rem 1rem !important;
  }

  hr {
    background: $light;
    border: none;
    height: 1px;
  }

  .card-footer {
    border-top: 1px solid $light;
    padding-top: 1rem;
  }
}

</style>
